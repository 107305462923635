import styled from 'styled-components';
import bgImageT from '../images/background.png';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-image: url(${bgImageT});
`;

export const Hero = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 8.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    padding: 0;
    margin: 0;
    font-size: 10vw;
    font-weight: bold;
    @media screen and (min-width: 1024px) {
      font-size: 5vw;
    }
  }
  p {
    padding: 0;
    margin-top: 1vh;
    line-height: 1.6;
    font-size: 3vw;
    @media screen and (min-width: 1024px) {
      font-size: 1.5vw;
    }
  }
`;

export const SocialUl = styled.ul`
  list-style: none;
  display: inline;
  margin: 0;
  padding: 0;
  li {
    font-size: 7vw;
    float: left;
    margin-right: 20px;
    @media screen and (min-width: 1024px) {
      font-size: 3.5vw;
    }
  }
  a {
    text-decoration: none;
    color: white;
  }
`;
