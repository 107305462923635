import { Container, Hero, SocialUl } from './StartStyle';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

const Start = () => {
  return (
    <Container>
      <Hero>
        <h1>JOHAN</h1>
        <h1>CEDERSTRAND</h1>
        <p>Frontend Development student @Medieinstitutet - Stockholm</p>
        <p>
          <strong>Graduation: </strong>2021-05-20
        </p>
        <SocialUl>
          <li>
            <a
              href='https://linkedin.com/in/johced'
              aria-label='View my linkedin profile'>
              <FaLinkedinIn />
            </a>
          </li>
          <li>
            <a
              href='https://github.com/johced'
              aria-label='View my github page'>
              <FaGithub />
            </a>
          </li>
          <li>
            <a href='mailto:johan.cederstrand@medieinstitutet.se'>
              <HiOutlineMail />
            </a>
          </li>
        </SocialUl>
      </Hero>
    </Container>
  );
};

export default Start;
