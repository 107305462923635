import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Start from './components/pages/Start';
import { GlobalStyle } from './GlobalStyle';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path='/'>
          <Start />
        </Route>
        <Route exact path='*'></Route>
      </Switch>
    </Router>
  );
}

export default App;
